import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
// import appLogo from '../../assets/images/multiline-logo-nobg.png';
import heroBackground from '../../assets/images/multiline-landing-bg.jpg';
import './Hero.less';

const Hero = () => {
    const intl = useIntl();
    const heroInnerContainerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (heroInnerContainerRef.current) {
                heroInnerContainerRef.current.classList.add('animate');
            }
        }, 100);

        return () => clearTimeout(timer);
    }, []);
        
    useEffect(() => {
        const backgroundImg = new Image();
        backgroundImg.src = heroBackground;
        backgroundImg.onload = () => {
            setIsLoading(false);
        };
    }, []);

    return (
        <div id="Home" className={`hero-container ${isLoading ? 'loading' : 'loaded'}`} >
            <div className="opacity-overlay" />
            <div ref={heroInnerContainerRef} className="hero-inner-container">
                {/* <div className="company-logo">
                    <img alt="company-logo-hero" className="company-logo-hero" src={appLogo} />
                </div> */}
                <div className="left-column">
                    <h1 className="heading">
                        {intl.formatMessage({ id: 'HEADING_LINE1' })}
                    </h1>
                    <p className="sub-heading">
                        A Refreshing Experience For Your Beloved Companion
                    </p>
                    <button className="primary-action"><a className="primary-action-link" href="#ContactUs" rel="noreferrer">{intl.formatMessage({ id: 'CONNECT_NOW' })}</a></button>
                </div>
            </div>
        </div>
    );
};

export default Hero;
