import React, { useState } from "react";
import ReactLazyPreload from '../utils/ReactLazyPreload';

import Hero from "../components/hero/Hero";
import Footer from "../components/footer/Footer";

import './Content.less';

const Content = () => {

    return (
        <div className="content-container">
            <Hero />
            <Footer />
        </div>
    );
};

export default Content;
